<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Шкала" prop="parent_id">
      <el-select
        v-model="scale.parent_id"
      >
        <el-option
          v-for="template in scaleTemplates"
          :key="template.id"
          :label="template.name"
          :value="template.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Единица измерения" prop="unit_id">
      <el-select
        v-model="scale.unit_id"
        filterable
      >
        <el-option
          v-for="unit in scalesUnits"
          :key="unit.id"
          :label="unit.symbol"
          :value="unit.id"
        ></el-option>
      </el-select>
    </el-form-item>


    <el-row :gutter="40">
      <el-col :span="8">
        <el-form-item label="Целевой уровень показателя" prop="target_level">
          <el-input
            v-model="scale.target_level"
            v-number-format="{decimals: 6}"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Промежуточное выполнение" prop="forecast">
          <el-input
            v-model="scale.forecast"
            v-number-format="{decimals: 6}"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Факт выполнения" prop="fact">
          <el-input
            v-model="scale.fact"
            v-number-format="{decimals: 6}"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>



    <el-row :gutter="40">
      <el-col :span="8">
        <el-form-item label="% выполнения КПЭ" prop="percent">
          <el-input
            v-model="scale.percent"
            v-number-format="{decimals: 2}"
            disabled
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="% выполнения по шкале" prop="scale_percent">
          <el-input
            v-model="scale.scale_percent"
            v-number-format="{decimals: 2}"
            disabled
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="% скорр" prop="correction">
          <el-input
            v-model="scale.correction"
            v-number-format="{decimals: 2}"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import requestSender from "@/api/base/requestSender";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    });
    requestSender('get', 'scale/templates-list', {
      sort: 'name',
    })
      .then(data => {
        // У нас может быть удаленный или залоченный темплейт. Нужно его тоже пушнуть в массив
        if( this.scale.parent ){
          if( !data.scales.find(t=>t.id===this.scale.parent.id) ){
            data.scales.push({
              id: this.scale.parent.id,
              name: this.scale.parent.name,
            })
          }
        }
        this.scaleTemplates = data.scales;
      })

    this.$api.library.getScalesUnits()
      .then(data => {
        // У нас может быть удаленная единица. Нужно ее тоже пушнуть в массив
        if( this.scale.unit ){
          if( !data.scales_units.find(t=>t.id===this.scale.unit.id) ){
            data.scales_units.push({
              id: this.scale.unit.id,
              symbol: this.scale.unit.symbol,
            })
          }
        }

        this.scalesUnits = data.scales_units;
      })
  },

  data() {
    return {
      scaleTemplates: [],
      scalesUnits: [],
      a:null,

      scale:{
        parent: null,
        unit: null,

        parent_id: null,
        unit_id: null,
        target_level: null,
        forecast: null,
        fact: null,
        percent: null,
        scale_percent: null,
        correction: null,
      },

      rules: {
        parent_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        unit_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        target_level: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
      }

    }
  },

  methods: {

  }
}
</script>

<style>

</style>